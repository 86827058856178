const areaList = {
    province_list: {
        110000: '北京市',
        120000: '天津市',
        130000: '河北省',
        140000: '山西省',
        150000: '内蒙古自治区',
        210000: '辽宁省',
        220000: '吉林省',
        230000: '黑龙江省',
        310000: '上海市',
        320000: '江苏省',
        330000: '浙江省',
        340000: '安徽省',
        350000: '福建省',
        360000: '江西省',
        370000: '山东省',
        410000: '河南省',
        420000: '湖北省',
        430000: '湖南省',
        440000: '广东省',
        450000: '广西壮族自治区',
        460000: '海南省',
        500000: '重庆市',
        510000: '四川省',
        520000: '贵州省',
        530000: '云南省',
        610000: '陕西省',
        620000: '甘肃省',
        630000: '青海省',
        640000: '宁夏回族自治区',
        650000: '新疆维吾尔自治区'
    },
    city_list: {
        110100: '北京市',
        120100: '天津市',
        130100: '石家庄市',
        130200: '唐山市',
        130300: '秦皇岛市',
        130400: '邯郸市',
        130500: '邢台市',
        130600: '保定市',
        130800: '承德市',
        130900: '沧州市',
        131000: '廊坊市',
        131100: '衡水市',
        140400: '长治市',
        140500: '晋城市',
        140100: '太原市',
        140200: '大同市',
        140300: '阳泉市',
        140800: '运城市',
        141000: '临汾市',
        141100: '吕梁市',
        150100: '呼和浩特市',
        150200: '包头市',
        150400: '赤峰市',
        150500: '通辽市',
        150600: '鄂尔多斯市',
        150700: '呼伦贝尔市',
        150800: '巴彦淖尔市',
        152500: '锡林郭勒盟',
        210100: '沈阳市',
        210200: '大连市',
        210300: '鞍山市',
        210400: '抚顺市',
        210600: '丹东市',
        210700: '锦州市',
        210900: '阜新市',
        211000: '辽阳市',
        211100: '盘锦市',
        211200: '铁岭市',
        211300: '朝阳市',
        211400: '葫芦岛市',
        220100: '长春市',
        220200: '吉林市',
        220300: '四平市',
        220500: '通化市',
        220700: '松原市',
        220800: '白城市',
        222400: '延边朝鲜族自治州',
        230100: '哈尔滨市',
        230600: '大庆市',
        230800: '佳木斯市',
        231200: '绥化市',
        310100: '上海市',
        320100: '南京市',
        320200: '无锡市',
        320300: '徐州市',
        320400: '常州市',
        320500: '苏州市',
        320600: '南通市',
        320700: '连云港市',
        320800: '淮安市',
        320900: '盐城市',
        321000: '扬州市',
        321100: '镇江市',
        321200: '泰州市',
        321300: '宿迁市',
        330100: '杭州市',
        330200: '宁波市',
        330300: '温州市',
        330400: '嘉兴市',
        330500: '湖州市',
        330600: '绍兴市',
        330700: '金华市',
        330800: '衢州市',
        331000: '台州市',
        331100: '丽水市',
        340100: '合肥市',
        340200: '芜湖市',
        340300: '蚌埠市',
        340400: '淮南市',
        340500: '马鞍山市',
        340600: '淮北市',
        340700: '铜陵市',
        340800: '安庆市',
        341100: '滁州市',
        341200: '阜阳市',
        341300: '宿州市',
        341500: '六安市',
        341600: '亳州市',
        341800: '宣城市',
        350100: '福州市',
        350200: '厦门市',
        350500: '泉州市',
        350600: '漳州市',
        350900: '宁德市',
        360100: '南昌市',
        360200: '景德镇市',
        360300: '萍乡市',
        360400: '九江市',
        360500: '新余市',
        360600: '鹰潭市',
        360700: '赣州市',
        360800: '吉安市',
        360900: '宜春市',
        361000: '抚州市',
        361100: '上饶市',
        370100: '济南市',
        370200: '青岛市',
        370300: '淄博市',
        370400: '枣庄市',
        370500: '东营市',
        370600: '烟台市',
        370700: '潍坊市',
        370800: '济宁市',
        370900: '泰安市',
        371000: '威海市',
        371100: '日照市',
        371300: '临沂市',
        371400: '德州市',
        371500: '聊城市',
        371600: '滨州市',
        371700: '菏泽市',
        410100: '郑州市',
        410200: '开封市',
        410300: '洛阳市',
        410400: '平顶山市',
        410500: '安阳市',
        410700: '新乡市',
        410800: '焦作市',
        410900: '濮阳市',
        411000: '许昌市',
        411200: '三门峡市',
        411300: '南阳市',
        411400: '商丘市',
        411600: '周口市',
        421100: '黄冈市',
        419001: '济源市',
        420100: '武汉市',
        420200: '黄石市',
        420300: '十堰市',
        421300: '随州市',
        420500: '宜昌市',
        420600: '襄阳市',
        421200: '咸宁市',
        422800: '恩施土家族苗族自治州',
        420900: '孝感市',
        430700: '常德市',
        430100: '长沙市',
        430400: '衡阳市',
        430500: '邵阳市',
        430300: '湘潭市',
        430200: '株洲市',
        430600: '岳阳市',
        430800: '张家界市',
        430900: '益阳市',
        431000: '郴州市',
        431100: '永州市',
        431200: '怀化市',
        431300: '娄底市',
        433100: '湘西土家族苗族自治州',
        441900: '东莞市',
        440600: '佛山市',
        440700: '江门市',
        440100: '广州市',
        441300: '惠州市',
        441400: '梅州市',
        440200: '韶关市',
        440300: '深圳市',
        441700: '阳江市',
        440800: '湛江市',
        440900: '茂名市',
        440400: '珠海市',
        442000: '中山市',
        445100: '潮州市',
        445200: '揭阳市',
        445300: '云浮市',
        450100: '南宁市',
        450200: '柳州市',
        450300: '桂林市',
        450900: '玉林市',
        451200: '河池市',
        450700: '钦州市',
        460100: '海口市',
        460200: '三亚市',
        460400: '儋州市',
        500100: '重庆市',
        510100: '成都市',
        510300: '自贡市',
        510400: '攀枝花市',
        510600: '德阳市',
        510700: '绵阳市',
        510800: '广元市',
        510900: '遂宁市',
        510500: '泸州市',
        511100: '乐山市',
        511000: '内江市',
        511300: '南充市',
        511400: '眉山市',
        511700: '达州市',
        511900: '巴中市',
        512000: '资阳市',
        520100: '贵阳市',
        520200: '六盘水市',
        522700: '黔南布依族苗族自治州',
        530100: '昆明市',
        530300: '曲靖市',
        530400: '玉溪市',
        530500: '保山市',
        530700: '丽江市',
        532300: '楚雄彝族自治州',
        532600: '文山壮族苗族自治州',
        532900: '大理白族自治州',
        610100: '西安市',
        610200: '铜川市',
        610300: '宝鸡市',
        610400: '咸阳市',
        610500: '渭南市',
        610700: '汉中市',
        610800: '榆林市',
        610900: '安康市',
        620100: '兰州市',
        620900: '酒泉市',
        630100: '西宁市',
        630200: '海东市',
        640100: '银川市',
        640400: '固原市',
        650100: '乌鲁木齐市',
        650500: '哈密市',
        652800: '巴音郭楞蒙古自治州',
        653100: '喀什地区',
        659001: '石河子市'
    }
}
export default areaList
