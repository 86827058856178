<template>
    <div class="home">
        <img src="../assets/images/banner.jpg" width="100%">
        <van-form @submit="onSubmit">
            <van-field
                    v-model="name"
                    name="outName"
                    label="姓名"
                    placeholder="请填写姓名"
                    :rules="[{ required: true, message: '请填写姓名' }]"
            />
            <van-field
                    v-model="phone"
                    type="tel"
                    name="outMobile"
                    label="手机号"
                    placeholder="请输入手机号"
                    :rules="[{ pattern: /^[1][3,4,5,7,8][0-9]{9}$/, message: '请输入正确手机号' }]"
            />
            <van-field name="collectCard" label="收卡方式">
                <template #input>
                    <van-radio-group v-model="collectCard" direction="horizontal">
                        <van-radio name="1">寄送</van-radio>
                        <van-radio name="0">电子卡</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <van-field v-if="collectCard==='1'"
                    readonly
                    clickable
                    name="outProvince"
                    :value="city"
                    label="地区选择"
                    placeholder="点击选择省市"
                    @click="showArea = true"
                    :rules="[{ required: true, message: '请选择地区' }]"
            />
            <van-popup v-model="showArea" position="bottom" round>
                <van-area
                        :columns-num="2"
                        :area-list="areaList"
                        :value="cityCode"
                        @confirm="onConfirm"
                        @cancel="showArea = false"
                />
            </van-popup>
            <van-field v-if="collectCard==='1'"
                    v-model="address"
                    rows="1"
                    autosize
                    type="textarea"
                    name="outAddr"
                    label="收货地址"
                    placeholder="请输入详细收货地址"
                    :rules="[{ required: true, message: '请输入详细收货地址' }]"
            />
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit">立刻参与体验</van-button>
            </div>
        </van-form>
        <van-overlay :show="isLoading">
            <div class="wrapper" @click.stop>
                <van-loading size="24px" vertical>提交信息中...</van-loading>
            </div>
        </van-overlay>
        <callPhone></callPhone>
    </div>
</template>

<script>

    import areaList from '../utils/citys'
    import axios from 'axios'
    import callPhone from '../components/callPhone'
    import {getCity , addInfo , getVerification} from "../api/api";

    export default {
        components:{
            callPhone
        },
        name: 'Home',
        data() {
            return {
                city: '',
                name: '',
                phone: '',
                address: '',
                tgid: '',
                cityCode:'',
                showArea: false,
                areaList: areaList, // 数据格式见 Area 组件文档
                isLoading: false,
                collectCard:'1'//收卡方式
            }
        },
        created() {
            getCity()
            .then(res=>{
                let city = res.data.province
                let city1 = res.data.city
                let stringAreaList = JSON.stringify(this.areaList)
                let indexIdx = stringAreaList.indexOf(city1)
                if(indexIdx === -1){
                    this.$toast({
                        message:'您所在城市暂未开放，请您切换城市参与活动',
                        forbidClick: true
                    })
                    this.$notify({
                        type: 'warning',
                        message: '您所在城市暂未开放，请您切换城市参与活动'
                    });
                }else{
                    this.city = city + '/' + city1
                    this.cityCode = stringAreaList.slice(indexIdx - 9 , indexIdx - 3)
                    if(!this.$route.query.code){
                        window.location.href='https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx4a39019346cdd4ec&redirect_uri=' + encodeURI('https://out.zcwipr.com/Home') + '&response_type=code&scope=snsapi_base&state=' + this.$route.query.tgid + '#wechat_redirect'
                    }else{
                        sessionStorage.setItem('code' , this.$route.query.code)
                        this.tgid = this.$route.query.state
                    }
                }
            })
        },
        methods: {
            onSubmit(infos) {
                this.isLoading = true
                setTimeout(()=>{
                    if (!this.tgid) {
                        this.$notify({
                            type: 'warning',
                            message: '获取推广码失败，请检查二维码是否有误'
                        });
                        return
                    }
                    infos.tgId = this.tgid
                    addInfo(infos)
                        .then((res) => {
                            if(this.collectCard === '1'){
                                this.$router.push({
                                    path:'Order',
                                    query:{
                                        name:this.name,
                                        phone:this.phone,
                                        address:this.address,
                                        city:this.city
                                    }
                                })
                            }else{
                                // 获取卡片代码
                                    this.$router.push({
                                        path:'Card',
                                        query:{
                                            name:this.name,
                                            phone:this.phone,
                                        }
                                    })
                            }
                        })
                    this.isLoading = false
                },1500)
            },
            onConfirm(values) {
                this.city = values
                    .filter((item) => !!item)
                    .map((item) => item.name)
                    .join('/');
                this.showArea = false;
            }
        }
    }
</script>
<style>
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
</style>
